/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    img: "img"
  }, _provideComponents(), props.components), {TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "useful-knowledge-about-the-hearing-test",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#useful-knowledge-about-the-hearing-test",
    "aria-label": "useful knowledge about the hearing test permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Useful knowledge about the hearing test"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The audiogram is a detailed graphical representation of your hearing ability in the form of a hearing curve. Based on the results, the hearing specialist will be able to identify your specific hearing impairment in a simple and quick manner. An ear-nose-throat specialist or any resident acoustician can perform a hearing test in order to assess your auditory perception."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The following contains important information pertaining to hearing tests. We will explain the following: how audiograms work and what the specialist (acoustician) will learn from your test results. Would you like to check your hearing ability? No problem! We can gladly schedule an appointment for a hearing test at one of our partner specialists in your area."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-precisely-is-an-audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-precisely-is-an-audiogram",
    "aria-label": "what precisely is an audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What precisely is an audiogram?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An audiogram records the so-called hearing threshold for various frequencies, which is the range that the human ear is just barely capable of perceiving a sound. A ready-made form is used for this purpose: Pitch is listed horizontally (Frequency in Hz), volume in dB vertically."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The zero line in an audiogram corresponds to the hearing threshold of an adolescent with normal hearing abilities. In the course of the measurement, two distinct values are distinguished. On the one hand, there is air conduction when sound travels from the outer ear via middle ear to the inner ear. This corresponds to what we typically associate with the term “hearing”. In addition, there is bone conduction when vibrations reach the inner ear by mediation of the cranium. In the ear, these signals are normally superposed by air conduction. When this is impaired, however, hearing aids are adaptable in order to take advantage of bone conduction. For this purpose, a much higher volume is necessary (approx. 50 dB more than airborne sound)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/audiogram.jpg",
    alt: "Example on of the audiogram",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "production-of-an-audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#production-of-an-audiogram",
    "aria-label": "production of an audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Production of an audiogram"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "First, the patient will put on headphones. Then, the acoustician will play a single tone starting with the frequency of 1000 Hz, which is generally recognizable for healthy ears. The tone will increase in increments of 5 dB until the patient can hear it. Subsequently, the process is repeated with other frequencies, and the respective hearing threshold is noted.\nAfter completing this test on both ears, the patient will put on a bone conduction receiver, which is placed directly on the cranium. Analogous to air conduction, the hearing threshold for bone conduction can be determined. By connecting hearing threshold values in this relative display – separate for each ear – hearing threshold curves for both air and bone conduction are obtained."), "\n", React.createElement(ButtonCta, {
    copy: "GET IN TOUCH WITH OUR EXPERTS!",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "results-and-interpretation-of-the-audiogram",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#results-and-interpretation-of-the-audiogram",
    "aria-label": "results and interpretation of the audiogram permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Results and interpretation of the audiogram"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/customer-doing-hearing-test.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Based on the audiogram, various forms of hearing impairment can be distinguished: there is air conduction when sound travels from the outer ear via the middle ear to the inner ear; and there is bone conduction when sound signals are relayed via the cranium. When tones are poorly recognized via air but well recognized via bone conduction, the expert will refer to this as a conductive hearing loss. The underlying cause can be anything that obstructs sound on its way from the outer to the inner ear. This can include a foreign body in the auditory canal, a middle ear effusion, or an issue with sound transfer from the eardrum to the ossicles. The ENT-specialist can often treat the cause of this conductive hearing impairment quite easily. Should this not be the case, hearing aids could be an option. We at hear.com can gladly give you advice.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In contrast, ", React.createElement(_components.a, {
    href: "/hearing-loss/sensorineural/",
    className: "c-md-a"
  }, "sensorineural hearing loss"), " originates in the inner ear or the associated nervous circuitry. Thus, the hearing curves in the audiogram run parallel for both, bone and air conduction, whereas the individual hearing threshold values markedly lie above the limits for people with normal hearing. The person with sensorineural hearing loss has trouble hearing the high frequencies: This range requires higher volumes until the hearing threshold can be determined. Thus, the hearing threshold curve declines in the high auditory range. This is a typical situation for age-dependent hearing impairment. In the case of a sensorineural hearing impairment, medication and surgery do not usually bring relief – a loss of inner ear function, however, can be alleviated with the use of hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thus, ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "a modern hearing aid"), " will enable you to keep actively participating in life without missing a conversation: An individual consultation and fitting process is, therefore, crucial! The staff at hear.com will gladly answer any questions you might have and pair you ", React.createElement(_components.a, {
    href: "/about-us/network",
    className: "c-md-a"
  }, "with a hearing aid specialist in your area.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
